const CommonString = {
  please_select_recruiter: "Please select recruiter.",
  add_comment: "Add Comment",
  update_comment: "Update Comment",
  all: "All",
  total_job_count: "Total Job Count",
  total_submission_count: "Total Submission Count",
  total_calls: "Total Calls",
  cancel: "Cancel",
  change: "Change",
  closed: "Closed",
  on_hold: "On Hold",
  ignored: "Ignored",
  covered: "Covered",
  confirm: "Confirm",
  clear_filter: "Clear Filter",
  add: "Add",
  call_link: "tel:",
  mail_link: "mailto:",
  mobile: `(max-width: 768px)`,
  loading: "loading...",
  update: "Update",
  download_attachment: "Download Attachment",
  resume: "resume",
  file: "file",
  years: " Years",
  outlined_variant: "outlined",
  sign_up: "Sign Up",
  dashboard: "Dashboard",
  sign_in: "Sign In",
  setting: "Setting",
  details: "Details",
  interview: "Interviews",
  project_detail: "Project",
  submission: "Submissions",
  uppercase_submission: "SUBMISSION",
  offer: "Offers",
  placement: "Placements",
  uploaded_applicant: "Applicants",
  view_submission: "View Submission",
  no_data: "NoData",
  positions: "Positions",
  applicants: "Applicants",
  recruiters: "Recruiters",
  partners: "Partners",
  running_placement: "Active Placement",
  jobs: "Jobs",
  client: "Client",
  count: "Count",
  right_end_placement: "right-end",
  top_placement: "top",
  log_out: "Log out",
  token: "Token",
  view_status: "ViewStatus",
  user: "User",
  completed: "COMPLETED",
  pending: "PENDING",
  save: "Save",
  cancle: "Cancel",
  delete: "Delete",
  clone: "Clone",
  edit: "Edit",
  yes: "Yes",
  no: "No",
  update_interview_details: "Update Interview Details",
  info: "info",
  success: "success",
  error: "error",
  permanent: "permanent",
  logo: "logo",
  call_1: "Call 1",
  call_2: "Call 2",
  call_3: "Call 3",
  view: "View",
  interview_details: "IV",
  small: "small",
  focused: "focused",
  partner_name: "Partner name",
  select_palceholder: "Select Placeholder",
  name: "Name",
  email: "Email",
  phone: "Phone",
  na: "NA",
  true: "true",
  false: "false",
  open: "OPEN",
  fieldset: "fieldset",
  radio_buttons_group: "radio-buttons-group",
  ascending: "Ascending",
  all_uppercase: "ALL",
  dollar: "$",
  percentage: "%",
  copied: "Copied",
  copy: "Copy",
  recruiter: "Recruiter",
  prexelite: "PREXELITE",
  read_more: "Read more",
  read_less: "Read less",
  submission_table_height: "73.5vh",
  job_table_height: "83.5vh",
  doc_pre_extention: "https://view.officeapps.live.com/op/embed.aspx?src=",
  doc_post_extention: "",
  pdf_post_extention: "#toolbar=0&navpanes=0",
  three_dot: "...",
  dashboard_table_height: "72vh",
  lazy: "lazy",
  pdf_type: "application/pdf",
  contained: "contained",
  table_cell: "tableCell",
  blank: "_blank",
  blank_text: "",
  top_right: "top right",
  root: "root",
  login_error_message: "User name and password seems to be incorrect",
  welcome: "Welcome!",
  ats: "ATS",
  text: "text",
  password: "password",
  show_password: " Show Password",
  updates: "Updates",
  notification: "Notification",
  keyString: "Key String",
  searchByPositonNameandID: "Search by position ID anf Position Name",
  search_name: "Name | Email | Designation | Phone",
};
const AccessKey = {
  close_applicant: "Close Applicant",
  add_partner_in_job: "Add Partner In Job",
  job_status_update: "Update Job Status",
  access: "Access",
  add_job: "Add Job",
  update_job: "Update Job",
  delete_job: "Delete Job",
  add_applicant: "Add Applicant",
  update_applicant: "Update Applicant",
  delete_applicant: "Delete Applicant",
  add_client: "Add Client",
  update_client: "Update Client",
  delete_client: "Delete Client",
  add_review: "Add Review",
  update_review: "Update Review",
  delete_review: "Delete Review",
  add_submission: "Add Submission",
  update_submission: "Update Submission",
  delete_submission: "Delete Submission",
  add_offer: "Add Offer",
  update_offer: "Update Offer",
  delete_offer: "Delete Offer",
  add_placement: "Add Placement",
  update_placement: "Update Placement",
  delete_placement: "Delete Placement",
  view_review: "View Review",
};
const CommonStringForLocalStorage = {
  token: "TOKEN",
  recruiter: "RECRUITER",
  user: "USER",
  view_status: "ViewStatus",
  dashboard: "Dashboard",
  client: "CLIENT",
  level: "LEVEL",
  partner: "PARTNER",
  client_local_data: "ClientLocalData",
  level_local_data: "LevelLocalData",
  partner_local_data: "PartnerLocalData",
};

const CommonStringForInterviewDetails = {
  date: "Date",
  interview_mode: "Interview Mode",
  note: "Note",
  point_of_contact: "Point Of Contact",
  time: "Time",
  web_ex_link: "Web Ex Link",
  offer_date: "Offer Date",
  payrate: "Pay Rate",
  billrate: "Bill Rate",
  conversion_1099: "Conversion 1099",
  margin: "Margin",
  comment: "Comment",
  start_date: "Start Date",
};
const CommonStringForProjectDetails = {
  end_date: "End Date",
  note: "Note",
};
const CommonStringForRecruiter = {
  name: "Name",
  level_name: "Level Name",
  prexelite_id: "Prexelite ID",
  email: "Email",
  phone: "Phone",
  birth_date: "Birth Date",
};

const CommonStringForPartner = {
  name: "Name",
  Partner_id: "Partner ID",
  poc_name: "POC Name",
  email: "Email",
  phone: "Phone",
};

const CommonStringForLevel = {
  level: "Level",
  designation: "Designation",
};

const CommonStringForClient = {
  poc_name: "Poc Name",
  poc_email: "Poc Email",
  margin: "Margin %",
  poc_designation: "Poc Designation",
  poc_phone: "Poc Phone",
  department: "Department",
  domain: "Domain",
  location: "Location",
  client_id: "Client ID",
};

const CommonStringForJob = {
  updated_by: "Updated By",
  added_by: "Added By",
  submission_made: "Submission Made",
  assigned: "Assigned",
  possition_title: "Position Title",
  partner_name: "Partner Name",
  client_id: "Client ID",
  client_position_id: "Client Position ID",
  department: "Department",
  assign_to: "Assign To",
  submission_tax_term: "Tax term",
  submission_pay_rate: "Pay Rate",
  client_name: "Client Name",
  job_location: "Job Location",
  postal_code: "Postal Code",
  job_duration: "Job Duration",
  open_date: "Open Date",
  due_date: "Due Date",
  max_submission: "Max Submission",
  job_description: "Job Description",
  no_of_submission: "Submission Made",
  total_opening: "Total Opening",
  contact_manager: "Contact Manager",
  prefered_skill: "Prefered Skill",
  required_skill: "Required Skill",
  required_education: "Require Education",
  experience: "Experience",
  skill_matrix: "Skill Matrix",
  assign_tax_term: "Assign Tax Term",
  expected_payrate: "Expected Pay Rate",
  submitted_by: "Submitted By",
  interview_mode: "Interview Mode",
  job_type: "Job Type",
  SDLC_Process: "SDLC Process",
  domain: "Domain",
  working_hour: "Working Hours",
  job_mode: "Job Mode",
  bill_rate: "Bill Rate",
  Job_duration_type: "Job Duration Type",
  comment: "Comment",
};

const CommonTitle = {
  update_status: "Update status",
  view_resume: "View Resume",
  change_status: "Select a Different Status",
  pdf: "pdf",
  doc: "doc",
  close: "Close",
  edit: "Edit",
  delete: "Delete",
  docx: "docx",
  update_submission_status: "Update Submission Status",
  connected_candidates: "Connected Consultant",
  no_candidate:
    "Currently do not have any potential candidates in our pipeline.",
  connected_applicant: "Connected for below Requisition",
  no_job: "No Requisition found",
};

const CommonButtonName = {
  clear: "Clear",
  update_status: "Update status",
  in_touch: "In Touch",
  lock_profile: "Lock Profile",
  upload_resume: "Upload resume",
  upload_attachment: "Upload Attachment",
  upload_more_attachment: "Upload More Attachment",
  create_group: "Create Group",
  add: "Add",
  submit: "Submit",
  apply: "Apply",
  download_report: "Download Report",
  change_password: "Change Password",
  forgot_password: "Forgot Password",
  sign_in: "Sign In",
  submit_profile: "Save Profile",
};

const CommonLabel = {
  password: "Password",
  date_time: "Day & Date",
  open_date: "Open Date",
  due_date: "Due Date",
  confirm_pasword: "Confirm Password",
  user_name: "User Name",
  birth_date: "Birth Date",
  offer_date: "Offer Date",
  start_date: "Start Date",
  end_date: "End Date",
  invoiced_till: "InVoiced Till",
  bd_date: "BD Date",
  pay_rate: "Pay rate",
  bill_rate: "Bill rate",
  conversion_1099: "1099 conversion",
  msp_vendor_fee: "MSP/Vendor_Fee",
  payroll_management_fees: "Payroll_Management_Fees",
  gp: "GP",
  prexel_share: "Prexel share",
  rw: "RW",
  bae: "BAE",
  it: "IT",
  comment: "Comment",
};

const CommonStringForApplicant = {
  designation: "Designation",
  address: "Address",
  tax_term: "Tax Term",
  skills: "Skills",
  comment: "Comment",
  work_authority: "Work Authority",
  expected_pay_rate: "Expected Pay Rate ($)",
  experience: "Experience",
  source_by: "Source By",
  added_by: "Added By",
  pay_rate: "Pay Rate",
};
const ConfirmationMessage = {
  active_partner_confirmation: "Are you sure you want to update partner status",
};

export const AlertTitle = {
  change_partner: "Partner Change",
};
export const AlertMessage = {
  partner_change_message:
    "Are you sure you want to change partner For this Submission?",
};

const ButtonName = {
  add_applicant: "Add Applicant",
  edit_applicant: "Edit Applicant",
  view_applicant: "View Applicant",
  close_applicant: "Close Applicant",
  load_more: "Load More..",
};
export const PlaceholderName = {
  job_duration_type: "Job Duration Type",
  job_mode: "Job Mode",
  working_hours: "Working Hours",
  required_education: "Required Education",
  years_of_experience: "Years Of Experience",
  interview_mode: "Interview Mode",
  job_type: "Job Type",
  add_industry: "Add Industry",
  client_name: "Client Name *",
  experience: "Experience",
  work_authorization: "Work Authorization",
  tax_term: "Tax Term",
  sourced_from: "Sourced From",
  domain: "Domain",
  duration: "Duration",
};
export {
  AccessKey,
  CommonStringForProjectDetails,
  ButtonName,
  ConfirmationMessage,
  CommonLabel,
  CommonStringForApplicant,
  CommonButtonName,
  CommonTitle,
  CommonStringForJob,
  CommonStringForClient,
  CommonStringForLevel,
  CommonStringForPartner,
  CommonStringForRecruiter,
  CommonString,
  CommonStringForLocalStorage,
  CommonStringForInterviewDetails,
};
