import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AccessKey } from "../../Utils/CommonString";
import { onChangeAccessData } from "../../feature/store/ReduxStore";
import { onCheckAdminAccessibility } from "../../Utils/utilitiFunction";
import CheckBox from "../basic/CheckBox";
import { SubTitle } from "../../style/common.style";
import { Grid } from "@mui/material";

export default function AddAccess(props) {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    job_create: false,
    job_update: false,
    job_delete: false,
    applicant_create: false,
    applicant_update: false,
    applicant_delete: false,
    client_create: false,
    client_update: false,
    client_delete: false,
    review_create: false,
    review_update: false,
    review_delete: false,
    submission_create: false,
    submission_update: false,
    submission_delete: false,
    offer_create: false,
    offer_update: false,
    offer_delete: false,
    placement_create: false,
    placement_update: false,
    placement_delete: false,
    job_status_update: false,
    add_partner_in_job: false,
    close_applicant: false,
    view_review: false,
  });
  useEffect(() => {
    onPrefilledData();
  }, [props.accessData]);

  function onPrefilledData() {
    if (props.accessData === null) return;
    setFormData(props.accessData);
  }

  function handleInputChange(event) {
    const { name, checked } = event.target;
    setFormData({ ...formData, [name]: checked });
    dispatch(onChangeAccessData(formData));
  }
  // console.log("formData", formData);

  return onCheckAdminAccessibility() ? (
    <>
      <SubTitle className="p-0 mb-2">{AccessKey.access}</SubTitle>
      <Grid container>
        <CheckBox
          onClick={handleInputChange}
          value={formData.job_create}
          label={AccessKey.add_job}
          name="job_create"
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.job_update}
          label={AccessKey.update_job}
          name={"job_update"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.job_delete}
          label={AccessKey.delete_job}
          name={"job_delete"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.job_status_update}
          label={AccessKey.job_status_update}
          name={"job_status_update"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.add_partner_in_job}
          label={AccessKey.add_partner_in_job}
          name={"add_partner_in_job"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.applicant_create}
          label={AccessKey.add_applicant}
          name={"applicant_create"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.applicant_update}
          label={AccessKey.update_applicant}
          name={"applicant_update"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.close_applicant}
          label={AccessKey.close_applicant}
          name={"close_applicant"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.applicant_delete}
          label={AccessKey.delete_applicant}
          name={"applicant_delete"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.client_create}
          label={AccessKey.add_client}
          name={"client_create"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.client_update}
          label={AccessKey.update_client}
          name={"client_update"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.client_delete}
          label={AccessKey.delete_client}
          name={"client_delete"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.view_review}
          label={AccessKey.view_review}
          name={"view_review"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.review_create}
          label={AccessKey.add_review}
          name={"review_create"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.review_update}
          label={AccessKey.update_review}
          name={"review_update"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.review_delete}
          label={AccessKey.delete_review}
          name={"review_delete"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.submission_create}
          label={AccessKey.add_submission}
          name={"submission_create"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.submission_update}
          label={AccessKey.update_submission}
          name={"submission_update"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.submission_delete}
          label={AccessKey.delete_submission}
          name={"submission_delete"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.offer_create}
          label={AccessKey.add_offer}
          name={"offer_create"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.offer_update}
          label={AccessKey.update_offer}
          name={"offer_update"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.offer_delete}
          label={AccessKey.delete_offer}
          name={"offer_delete"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.placement_create}
          label={AccessKey.add_placement}
          name={"placement_create"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.placement_update}
          label={AccessKey.update_placement}
          name={"placement_update"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.placement_delete}
          label={AccessKey.delete_placement}
          name={"placement_delete"}
        />
      </Grid>
    </>
  ) : (
    <></>
  );
}
